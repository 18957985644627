'use client'

export default function GlobalError(
  {
    error,
    reset,
  }: {
    error: Error & { digest?: string }
    reset: () => void
  }) {
  return (
    <html>
    <body>
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1
            className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">500</h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Internal
            Server Error.</p>
          <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">We are already working to solve the
            problem. </p>
          <button
            className='mt-4 rounded-md bg-blue-500 px-4 py-2 text-sm text-white transition-colors hover:bg-blue-400'
            onClick={() => reset()}
          >
            Try again
          </button>
        </div>
      </div>
    </section>
    </body>
    </html>
  )
}